@import url('https://fonts.googleapis.com/css2?family=Inria+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

main {
  margin: 0 auto;
  max-width: 90%;
  padding: 50px 30px;
}
:root {
  --primary: #f9fc38;
  --secondary: #020b0f;
  --tertiary: #ff0040;
  --light: #fafafa;
  --dark: #d1d0d0;
  --darker: #111111;
  --gray:#464646;
  --error: rgb(228, 46, 1);
  --spacing-one: calc(1rem * 0.5);
  --spacing-two: calc(1rem);
  --spacing-five: calc(2.5rem);
}

*,
*:after,
*:before {
  box-sizing: border-box;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--light); 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--dark); 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--gray); 
}

h1::first-letter, 
h2::first-letter, 
h3::first-letter {
  color: var(--tertiary);
  } 

a:link {
  text-decoration: none;
}
  
a:visited {
  text-decoration: none;
}
  
a:hover {
  text-decoration: none;
}
  
a:active {
  text-decoration: none;
}

html
{
 overflow-x: hidden;
}

body {
  width: 100vw;
  margin: 0;
  padding: 0;
  font-weight: 600;
  line-height: 1.5;
  font-size: 18px;
  letter-spacing: 0.1em;
  background: linear-gradient(0deg,var(--dark), var(--light));
  color: var(--secondary);
  font-family: 'Inria Sans', sans-serif;
  z-index: 1;
  overscroll-behavior-y: contain;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  margin: 5px 0;
  line-height: 1.25;
}

h1 {
  font-size: 3.25rem;
  border-bottom: 3px solid var(--tertiary);
  padding-bottom: 20px;
  text-align: center;
  margin: 0 auto 35px auto;
  width: 50%;
}

.bottom-border{
  border-bottom: 2px solid var(--tertiary);
  width: 20%;
  position: relative;
  margin-left: 82vh;
  margin-bottom: 10px;

}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

p {
  font-size: 1.1rem;
  margin-top: 0;
  text-indent: 0px;
}

a {
  color: var(--tertiary);
  text-decoration: none;
  font-weight: bold;
}

a:link {
  text-decoration: none;
}
  
a:visited {
  text-decoration: none;
}
  
a:active {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

header {
  background: var(--secondary);
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
  animation: 500ms ease-in-out 0s normal none 1 running;
  padding-top: 0px;
  padding-bottom: 0px;
}

header a {
display: inline-block;
color: var(--light);
position: absolute;
top: 18px;
left: 10px;
}

header nav {
  margin: 7px 0;
}

button {
  font-size: 1rem;
  text-decoration: none;
  padding: 0.25rem 1rem;
  border-radius: 0.5rem;
  border: none;
  outline: none;
  cursor: pointer;
  margin: 0 1px;
  background-color: var(--secondary);
}

button:hover {
  opacity: 0.8;
}

button[disabled] {
  cursor: default;
  opacity: 0.8;
}

img {
  max-width: 100%;
}

span[role='img'] {
  cursor: default;
}

form {
  width: 40%;
}

input {
  font-size: 1rem;
  padding: 0.25rem;
  border: 1px solid var(--gray);
  outline: none;
  border-radius: 3px;
  transition: box-shadow 0.2s;
}

input:focus {
  border: 1px solid var(--secondary);
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.5);
}

li {
  list-style: none;
}

nav a:hover {
  text-decoration: none;
}

nav li {
  cursor: pointer;
}

textarea {
  width: 600px;
  height: 120px;
  border: 3px solid #cccccc;
  padding: 5px;
}


.container {
  width: 85%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.flex-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.flex-row-2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.space-between {
  justify-content: space-between;
}

.flex-end {
  justify-content: flex-end;
}

.mx-1 {
  margin-right: var(--spacing-one);
  margin-left: var(--spacing-one);
}

.mx-2 {
  margin-right: var(--spacing-two);
  margin-left: var(--spacing-two);
}

.my-1 {
  margin-top: var(--spacing-one);
  margin-bottom: var(--spacing-one);
}

.my-2 {
  margin-top: var(--spacing-two);
  margin-bottom: var(--spacing-two);
}

.my-5 {
  margin-top: var(--spacing-five);
  margin-bottom: var(--spacing-five);
}

.px-1 {
  padding-right: var(--spacing-one);
  padding-left: var(--spacing-one);
}

.px-2 {
  padding-right: var(--spacing-two);
  padding-left: var(--spacing-two);
}

.py-1 {
  padding-top: var(--spacing-one);
  padding-bottom: var(--spacing-one);
}

.py-2 {
  padding-top: var(--spacing-two);
  padding-bottom: var(--spacing-two);
}

.card {
  width: 25%;
  text-align: center;
}

.img-thumbnail {
  width: auto;
  height: 300px;
  margin-bottom: 50px;
  cursor: pointer;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out; 
}

.sm-link{
  top: 0;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
  }

.sm-link img{
  width:42px;
  height:42px;
  }

.img-thumbnail:hover, 
.sm-link a:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
  }

.modalBackdrop {
  background: rgba(0, 0, 0, 0.65);
  bottom: 0;
  left: 0;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2;
}

.modalContainer {
  background: var(--darker);
  border-radius: 5px;
  max-width: 100%;
  margin: 50px auto;
  padding: 15px;
  width: 960px;
  top: 5vh;
  position: relative;
  color: var(--light);
  text-align: center;
}

.modalTitle {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 25px;
  color: var(--light);
  text-align: center;
}

button {
  background: var(--primary);
  border: 0;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 30px;
  padding: 10px 15px;
}

a {
  border-radius: 5px;
  color: var(--primary);
  font-weight: 600;
  padding: 2px 4px;
  margin-left: -2px;
  margin-right: -2px;
}

.flex {
  display: flex;
  align-items: center;
}
.nav-right{
  margin-left: auto;
}
.navActive {
  color: var(--primary);
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--light);
  background-image:
  radial-gradient(var(--dark) 9px, transparent 10px),
  repeating-radial-gradient(var(--dark) 0, var(--dark) 4px, transparent 5px, transparent 20px, var(--dark) 21px, var(--dark) 25px, transparent 26px, transparent 50px);
  background-size: 30px 30px, 90px 90px;
  background-position: 0 0;
  max-width: 100%;
  margin-bottom: 50px auto;
  padding: 15px;
  width: 100%;
  height:100vh;
  z-index: 0;
  border-bottom: 10px solid var(--secondary);
}

.deck {
  position: absolute;
  width: 300px;
  height: 200px;
  will-change: transform;
  display: flex;
  align-items: center;
  justify-content: center;
  touch-action: none;
  z-index: 0;
}

.deck > div {
  background-color: white;
  background-size: auto 85%;
  background-repeat: no-repeat;
  background-position: center center;
  width: 45vh;
  max-width: 150px;
  height: 85vh;
  max-height: 285px;
  will-change: transform;
  border-radius: 10px;
  box-shadow: 0 5px 50px -10px var(--gray), 0 10px 10px -10px var(--gray);
  z-index: 0;
}

.card {
  height: 600px !important;
  width: 100px;
  margin-left: 15%;
  margin-top: 15%;
  -webkit-user-select: none;  
  -moz-user-select: none;    
  -ms-user-select: none;      
  user-select: none;
}

.card > div {
  height: 380px !important;
}

.card > h3 {
  display: inline-block;
  text-align: center;
}

.btn:after,
.btn:before {
  backface-visibility: hidden;
  border: 1px solid var(--secondary);
  bottom: 0px;
  content: " ";
  display: block;
  margin: 0 auto;
  position: relative;
  transition: all 280ms ease-in-out;
  width: 0;
  z-index: 1000;
}

.btn:hover:after,
.btn:hover:before {
  backface-visibility: hidden;
  border-color: var(--tertiary);
  transition: width 350ms ease-in-out;
  width: 70%;
  z-index: 1000;
}

.btn:hover:before {
  bottom: auto;
  top: 0;
  width: 70%;
  z-index: 1000;
}

.contact-container{
  width: 100vw;
  background-color: var(--secondary);
  border-top: 5px solid var(--light);
}

.contact-container h1{
  color: var(--light);
  margin-bottom: 5px;
  text-align: center;
}

.contact-section{
margin-left: 5vw;
color: var(--light);
margin-top: 20px;
}

#contact-info
{
display: block;
position: relative;
float: right;
top: 0;
margin-right: 5vw;
margin-top: 20px;
padding-left: 50px;
}

#contact-info img{
  width: 200px;
  height: 200px;
}


.center{
  text-align: center;
}

.left{
  display: block;
  float: left;
}

.text-left{
  text-align: left;
}

.right{
  display: block;
  float: right;
}
.text-right{
  text-align: right;
  text-indent: 0;
}

.abt-image{
  top: 0;
  width: 35vw;
  height: 35vh;
}

footer{
  padding: 1rem;
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: var(--darker);
  border-top: 2px solid var(--light);
}

footer p{
  margin-top: 10px;
  color: var(--light);
}

.arrow {
  position: absolute;
  bottom: 50px;
  box-sizing: border-box;
  height: 5vw;
  width: 5vw;
  border-style: solid;
  border-color: var(--gray);
  border-width: 0px 1px 1px 0px;
  transform: rotate(45deg);
  transition: border-width 150ms ease-in-out;
}

.arrow:hover {
  border-bottom-width: 4px;
  border-right-width: 4px;
}

.contact-container p,
.nav{
  color: var(--light);
}

@media screen and (max-width: 980px) {

  textarea
  {
  width: 50vw;
  }

}

/* MEDIA QUERY FOR TABLETS AND SMALLER */
@media screen and (max-width: 768px) {

header a{
  display: none;
}

.flex-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-size: 1rem;
}

#contact-info img{
  width: 100px;
  height: 100px;
}

}
  
  /* MEDIA QUERY FOR MOBILE PHONES AND SMALLER */
@media screen and (max-width: 575px) {

  .flex-row {
    font-size: 0.7rem;
  }

  h1 {
    font-size: 2.20rem;
    border-bottom: 3px solid var(--tertiary);
    padding-bottom: 20px;
    text-align: center;
    margin: 0 auto 35px auto;
    width: 50%;
  }

  h2 {
    font-size: 1.6rem;
  }
  
  h3 {
    font-size: 1.3rem;
  }
  
  h4 {
    font-size: 1.5rem;
  }
  
  h5 {
    font-size: 1.1rem;
  }
  
  h6 {
    font-size: .7rem;
  }
  
  p {
    font-size: 0.88rem;
    margin-top: 0;
    text-indent: 0px;
  }

  #contact-info{
    display: none;
  }
  }
  